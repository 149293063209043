body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.list_farbe input {
  opacity: 1;
  padding: 10px;
  color: transparent;
}

.farbess {
  display: inline-block;
  width: 50px;
  color: transparent;
  border: 1px solid #ccc;
}

.farbe0 {
  background-color: #fff;
}

.list_farbe select option[value="1"],
.list_farbe input[value="1"],
#menu-farbe ul li:nth-child(1),
.farbe1 {
  background-color: #c80002;
}
.list_farbe select option[value="2"],
.list_farbe input[value="2"],
#menu-farbe ul li:nth-child(2),
.farbe2 {
  background-color: #ffd600;
}
.list_farbe select option[value="3"],
.list_farbe input[value="3"],
#menu-farbe ul li:nth-child(3),
.farbe3 {
  background-color: #525252;
}
.list_farbe select option[value="4"],
.list_farbe input[value="4"],
#menu-farbe ul li:nth-child(4),
.farbe4 {
  background-color: #cccccc;
}
.list_farbe select option[value="5"],
.list_farbe input[value="5"],
#menu-farbe ul li:nth-child(5),
.farbe5 {
  background-color: #00aeef;
}
.list_farbe select option[value="6"],
.list_farbe input[value="6"],
#menu-farbe ul li:nth-child(6),
.farbe6 {
  background-color: #7925c7;
}
.list_farbe select option[value="7"],
.list_farbe input[value="7"],
#menu-farbe ul li:nth-child(7),
.farbe7 {
  background-color: #f75c1e;
}
.list_farbe select option[value="8"],
.list_farbe input[value="8"],
#menu-farbe ul li:nth-child(8),
.farbe8 {
  background-color: #92c83e;
}
.list_farbe select option[value="9"],
.list_farbe input[value="9"],
#menu-farbe ul li:nth-child(9),
.farbe9 {
  background-color: #f766ae;
}

.projects-grid .RaDatagrid-root .css-kclhem {
  position: sticky;
  top: 105px;
  z-index: 5;
}

.MuiPaper-root .RaDatagrid-root .RaBulkActionsToolbar-toolbar {
  z-index: 100;
  -webkit-justify-content: normal;
  justify-content: normal;
}
.MuiPaper-root .RaDatagrid-root .RaBulkActionsToolbar-toolbar .projects-bulk {
  padding-left: 40px;
}
.MuiPaper-root .RaDatagrid-root .RaBulkActionsToolbar-toolbar .bulkEditRow,
.MuiPaper-root
  .RaDatagrid-root
  .RaBulkActionsToolbar-toolbar
  .projects-bulk
  > div,
.MuiPaper-root
  .RaDatagrid-root
  .RaBulkActionsToolbar-toolbar
  .MuiButtonBase-root {
  display: inline-block;
  padding-right: 40px;
}
.RaSimpleFormIterator-line {
  padding-top: 10px;
}

.projects-grid.list-page {
  position: relative;
}
.projects-grid.list-page .RaList-main > .MuiToolbar-regular.MuiToolbar-root {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  z-index: 100;
  background-color: #fafafb;
  top: 60px;
}
.projects-grid.list-page .RaList-content .css-1romx45-RaBulkActionsToolbar-root,
.projects-grid.list-page .RaList-content .css-5ib9tr {
  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  z-index: 100;
  background-color: #fafafb;
  top: 124px;
}
.ck.ck-content{
  min-height: 200px;
}